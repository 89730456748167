import React from 'react'
import { graphql } from 'gatsby'

import Pager from '../components/pagerEvents'
import Master from '../components/Master';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Products from '../components/ListView/Products/Products';

const ProductsPaginate = ({ data, pageContext }) => {
    const products = data.allEvent.nodes
    const page = data.allPage.nodes[0]
    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/festas`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}/festas`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <h1>{page.title}</h1>
            <Pager pageContext={pageContext} />
            <Products products={products} />
            <Pager pageContext={pageContext} />
        </Master>
    )
}

export const query = graphql`
    query($skip: Int!, $limit:Int!){
        allEvent(
            sort: {fields:updatedAt, order: DESC}
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                title
                slug
                price
                description
                image
                imageThumbnail
                isEvent
            }
        }
        allPage(filter: {id: {eq: "5e90722f44198b139a67a073"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`;

export default ProductsPaginate